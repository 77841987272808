<template>
  <div class="customer_reviews" id="scrollspyHeading4">
    <h2>{{ $t("customer_app") }}</h2>
    <div class="reviews_lists">
      <div class="review_list" v-for="(list, index) in data.rates" :key="index">
        <div class="head">
          <div class="text">
            <img src="@/assets/image/Unknown_person.jpg" alt="unkown">
            <h6>{{ list.user_name }}</h6>
            <div class="rating">
              <span>
                <i class="bi bi bi-star-fill" v-for="(star, i) in list.rate" :key="i"></i>
                <i class="bi bi bi-star" v-for="(star, i) in 5-list.rate" :key="i"></i>
              </span>
            </div>
            <span>{{ list.time }}</span>
          </div>
        </div>
        <p>{{ list.comment }}</p>
      </div>
      <div class="button">
        <button
        
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          to="#"
          class="btn first"
        >
          {{ $t("add_comment") }}
        </button>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <Rate />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Rate from "@/components/lawyerDetials/content/addReview.vue";

export default {
  components: {
    Rate,
  },
  data() {
    return {
      ratingStars: [],
      data: {},
    };
  },
  methods: {
    fetch_review_data() {
      let id = { lawyer_id: this.$route.params.id };
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("/lawyer_details", id).then(({ data }) => {
        this.data = data.data;
        // console.log(this.data);
      });
    },
  },
  created() {
    this.fetch_review_data();
  },
};
</script>

<style></style>
