<template>
  <footer>
    <div class="container">
      <div class="content">
        <div class="logo">
          <img src="@/assets/image/logo_dark.png" alt="logo" />
        </div>
        <ul>
          <li>
            <router-link class="nav-link" to="/">{{ $t("home") }}</router-link>
          </li>
          <li>
            <router-link class="nav-link" to="/guide">{{
              $t("guide")
            }}</router-link>
          </li>
          <li>
            <router-link class="nav-link" to="/about">{{
              $t("about")
            }}</router-link>
          </li>
          <li>
            <router-link class="nav-link" to="/service">{{
              $t("service")
            }}</router-link>
          </li>
          <li>
            <router-link class="nav-link" to="/blogs">{{
              $t("blog")
            }}</router-link>
          </li>
          <li>
            <router-link class="nav-link" to="/contact">{{
              $t("contact")
            }}</router-link>
          </li>
          <li>
            <router-link class="nav-link" to="/contracts">{{
              $t("contracts")
            }}</router-link>
          </li>
        </ul>
        <p class="lawyer">
          {{ $t("are_yoy_laweyer") }}
          <router-link :to="{ name: 'JoinLawer' }" class="join">
            {{ $t("join") }}</router-link
          >
          <router-link class="join" :to="{ name: 'Adv' }">
            {{ $t("ad") }}</router-link
          >
        </p>
      </div>

      <div class="lower_footer">
        <div class="item text-center">
          <a href="https://crazyideaco.com" target="_blank"
            >Made with
            <img
              class="my-heart"
              src="@/assets/image/heart.svg"
              alt="icon heart"
            />
            by Crazy Idea</a
          >
          <span>Think Out Of The Box</span>
        </div>
        <ul class="social">
          <li>
            <a :href="data.facebook" target="__blank">
              <i class="bi bi-facebook"></i>
            </a>
          </li>

          <li>
            <a :href="data.twitter" target="__blank">
              <i class="bi bi-twitter"></i>
            </a>
          </li>

          <li>
            <a :href="data.linkedin" target="__blank">
              <i class="bi bi-linkedin"></i>
            </a>
          </li>

          <li>
            <a :href="data.instagram" target="__blank">
              <i class="bi bi-instagram"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import { contactData } from "@/api/contact/information.js";

export default {
  name: "Footer",
  data() {
    return {
      data: {},
    };
  },
  mounted() {
    contactData().then((response) => {
      this.data = response.data.data;
      // console.log(this.header);
      return response;
    });
  },
};
</script>

<style scoped>
footer .footer-bottom .my-heart {
  animation: hvr-pulse 0.3s infinite;
}
.item a {
  display: block;
  font-size: 0.8em;
  color: #000;
  text-decoration: none !important;
  font-family: "bold";
}
.my-heart {
  width: 15px;
}
</style>
