<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img src="@/assets/image/logo.png" class="logo" alt="logo" />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" to="/">{{ $t("home") }}</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/guide">{{
              $t("guide")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/about">{{
              $t("about")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/service">{{
              $t("service")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/blogs">{{
              $t("blog")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/contact">{{
              $t("contact")
            }}</router-link>
          </li>

          <!-- language -->
          <!-- <div class="dropdown lang">
            <a
              class="btn dropdown-toggle"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-globe2"></i>
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <li>
                <a
                  class="dropdown-item"
                  v-for="entry in languages"
                  :key="entry.title"
                  @click="changeLocale(entry.language)"
                  :iso="entry.flag"
                  v-bind:squared="false"
                >
                  <flag :iso="entry.flag" v-bind:squared="false" />
                  {{ entry.title }}
                </a>
              </li>
            </ul>
          </div> -->

          <a
            class="lang"
            v-if="langStorage == 'en'"
            @click="changeLocale('ar')"
          >
            <img
              class="icon"
              src="@/assets/image/saudi-arabia.png"
              alt="egypt"
            />
            <!-- <span>{{ $t("arabic") }}</span> -->
          </a>
          <a
            class="lang"
            v-else-if="langStorage == 'ar'"
            @click="changeLocale('en')"
          >
            <img
              class="icon"
              src="@/assets/image/united-states.png"
              alt="united-states"
            />
            <!-- <span>{{ $t("english") }}</span> -->
          </a>
          <a class="lang" v-else @click="changeLocale('ar')">
            <img
              class="icon"
              src="@/assets/image/saudi-arabia.png"
              alt="egypt"
            />
            <!-- <span>{{ $t("arabic") }}</span> -->
          </a>
          <!-- language -->
        </ul>

        <div
          class="d-flex align-items-center gap-2 bg-black"
          v-if="!$store.state.userAuth && !user"
        >
          <router-link class="btn login first mx-2" :to="{ name: 'JoinLawer' }">
            {{ $t("join") }}
          </router-link>

          <router-link class="btn login first" :to="{ name: 'login' }">
            {{ $t("login") }}
          </router-link>
        </div>

        <div class="d-flex align-items-center gap-2 bg-black" v-else>
          <button class="btn login first" @click="logout">
            {{ $t("Logout") }}
          </button>
          <router-link
            class=""
            :to="`${user?.is_lawyer ? '/dashboard' : '/dashboard-user'}`"
          >
            <img
              src="@/assets/image/user.png"
              width="50"
              height="50"
              alt="user image"
            />
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "navbar",
  data() {
    return {
      langStorage: localStorage.getItem("lang"),
      user: JSON.parse(localStorage.getItem("user")) || "",
    };
  },
  computed: {
    isLoggedIn() {
      return !!localStorage.getItem("user");
    },
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
      const html = document.documentElement; // returns the html tag
      html.setAttribute("lang", locale);
      // this.$forceUpdate('lang',locale);
      localStorage.setItem("lang", locale);
      location.reload();
      // console.log(locale);
    },
    logout() {
      // remove the "user" key from local storage
      localStorage.removeItem("user");
      localStorage.removeItem("token-snadk");
      // redirect to the login page
      location.reload();
      this.$router.push({ name: "login" });
    },
  },
  created() {
    const html = document.documentElement; // returns the html tag
    html.setAttribute("lang", this.$i18n.locale);
  },
};
</script>

<style scoped>
.lang {
  cursor: pointer;
}
.lang img {
  width: 40px;
  height: 40px;
}
</style>
