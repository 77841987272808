<template>
  <div class="row" v-show="grid">
    <div
      class="col-lg-6 col-12"
      v-for="(card, index) in lawyercards.data"
      :key="index"
    >
      <lawyerCardsGrid :card="card" />
    </div>
    <div class="row">
      <pagination
        :data="lawyercards"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetch_lawyers_data"
      >
        <span slot="prev-nav">&lt;</span>
        <span slot="next-nav">&gt;</span>
      </pagination>
    </div>
  </div>
</template>

<script>
import lawyerCardsGrid from "@/components/lawyer/lawyerCardsGrid.vue";
import pagination from "laravel-vue-pagination";
export default {
  components: { lawyerCardsGrid, pagination },
  props: ["grid", "lawyercards", "fetch_lawyers_data", "noDataMessage"],
};
</script>

<style></style>
