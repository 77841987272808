<template>
  <div class="reservation">
    <h4 class="testt" v-if="!data.law_categories.length">
      {{ $t("no_service_law") }}
    </h4>

    <div class="text" v-if="data.law_categories.length">
      <h2>{{ $t("contonuis_reservation") }}</h2>
    </div>
    <div class="type" v-if="data.law_categories.length">
      <form class="advice" @submit.prevent="submitForm">
        <h4>{{ $t("choise_service") }}</h4>
        <!-- service -->

        <div class="input-group">
          <select
            class="form-select"
            aria-label="Default select example"
            @change="
              fetch_service_data();
              update_service_value();
            "
            v-model="law_category_id"
          >
            <!-- <option disabled selected>{{ $t("service") }}</option> -->
            <option selected disable value="serviceValue">
              {{ serviceValue }}
            </option>
            <option
              v-for="(cat, index) in data.law_categories"
              :key="index"
              :value="cat.id"
            >
              {{ cat.title }}
            </option>
          </select>
        </div>

        <!-- service -->

        <h4>{{ $t("choose_reservation") }}</h4>
        <div class="d-flex w-100">
          <div class="form-check w-50">
            <div class="input-group">
              <label for="zero">
                <input
                  class="form-control"
                  type="radio"
                  value="1"
                  id="zero"
                  v-model="selectedValue"
                />
                <div class="contents">
                  <span><i class="bi bi-camera-video-fill"></i></span>
                  <h6>{{ $t("vedio_consult") }}</h6>
                </div>
              </label>
            </div>
          </div>
          <div class="form-check w-50">
            <div class="input-group">
              <label for="one">
                <input
                  class="form-control"
                  type="radio"
                  value="2"
                  id="one"
                  v-model="selectedValue"
                />
                <div class="contents">
                  <span><i class="bi bi-record-btn-fill"></i></span>
                  <h6>{{ $t("call_consult") }}</h6>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="take_day">
          <h6>{{ $t("take_day") }}</h6>
          <input v-model="dateTime" class="form-control" type="date" />
        </div>

        <button class="btn first case" :disabled="user?.is_lawyer">
          {{ $t("book_now") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      selectedValue: "",
      user: JSON.parse(localStorage.getItem("user")),
      auth: localStorage.getItem("token-snadk"),
      dateTime: null,
      cards: {
        data: [],
      },
      data: {
        law_categories: [],
      },
      law_category_id: "",
      serviceValue: "",
    };
  },
  mounted() {
    window.addEventListener("popstate", this.handlePopstate);
  },

  beforeDestroy() {
    window.removeEventListener("popstate", this.handlePopstate);
  },
  methods: {
    //make select diseabled when key in local storage have value
    isServiceValueEmpty() {
      const caseKey = localStorage.getItem("casekey1");
      if (caseKey) {
        const { serviceValue } = JSON.parse(caseKey);
        if (serviceValue) {
          this.serviceValue = serviceValue;
        }
        return !serviceValue;
      }
      return true;
    },
    fetch_data_data() {
      let id = { lawyer_id: this.$route.params.id };
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("/lawyer_details", id).then(({ data }) => {
        this.cards = data.data;
        // console.log(this.cards);
      });
    },

    async submitForm() {
      // Validate phone number

      if (this.auth) {
        let data = {
          // lawyer_id: localStorage.getItem("id"),
          user_name: JSON.parse(localStorage.getItem("user")).name,
          user_phone: JSON.parse(localStorage.getItem("user")).phone,
          contact_type: this.selectedValue,
          date: this.dateTime,
          lawyer_id: this.$route.params.id,
          law_category_id: this.law_category_id,
        };
        const newLocal = this.$i18n.locale;
        axios.defaults.headers.common["Accept-Language"] = newLocal;
        axios.defaults.headers.common["Authorization"] = `Bearer ${this.auth}`;
        axios.post("/storeCaseRequest", data).then(({ data }) => {
          if (data.status == true) {
            Swal.fire({
              position: "top-center",
              icon: "success",
              title: data.message,
              timer: 1500,
            });
            localStorage.removeItem("casekey");
            localStorage.removeItem("casekey1");
          } else if (data.status == false) {
            Swal.fire({
              position: "top-left",
              icon: "error",
              title: data.message,
              timer: 1500,
            });
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: this.$t("Please_login_first"),
          timer: 1500,
        });
      }
    },

    // saveVar() {
    //   // Create an object with the retrieved servicekey value and other required properties
    //   const objectToSave = {
    //     contact_type: this.selectedValue,
    //     date: this.dateTime,
    //   };
    //   // Save the object to local storage as a JSON string
    //   localStorage.setItem("casekey", JSON.stringify(objectToSave));
    // },
    fetch_service_data() {
      let id = { lawyer_id: this.$route.params.id };
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("/lawyer_details", id).then(({ data }) => {
        this.data = data.data;

        // console.log(this.cards);

        //check if local storage value have service
        if (localStorage.getItem("casekey1")) {
          const casekey1 = JSON.parse(localStorage.getItem("casekey1"));
          console.log(casekey1.serviceValue);
          if (casekey1.serviceValue == "") {
            // Update the value of casekey1 in local storage with the new law_services_id value
            casekey1.serviceValue = this.law_category_id;
            localStorage.setItem("casekey1", JSON.stringify(casekey1));
          }
        }
      });
    },
    update_service_value() {
      // Update the value of casekey1 in local storage with the new law_services_id value
      const casekey1 = JSON.parse(localStorage.getItem("casekey1")) || {};
      casekey1.serviceValue = this.law_category_id;
      localStorage.setItem("casekey1", JSON.stringify(casekey1));
      // Reload the page
      // location.reload();
    },
    handlePopstate() {
      localStorage.removeItem("casekey1");
    },
  },
  created() {
    this.fetch_data_data();
    this.fetch_service_data();
  },
};
</script>

<style></style>
