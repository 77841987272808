<template>
  <div class="tabel_sec">
    <h2>{{ $t("date_payment") }}</h2>
    <table class="table" id="RequestTableData">
      <thead>
        <tr>
          <th>{{ $t("consult") }}</th>
          <th>{{ $t("value") }}</th>
          <th>{{ $t("percentage") }}</th>
          <th>{{ $t("consult_time") }}</th>
          <th>{{ $t("status") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(request, index) in requestTable" :key="index">
          <td>
            {{
              request.contact_type == 1
                ? $t("vedio_consultant")
                : request.contact_type == 2
                ? $t("call_consultant")
                : ""
            }}
          </td>
          <td>{{ request.guide_price }}</td>
          <td>{{ request.guide_percentage }}</td>

          <td>
            {{ formatHr(request.from_time) }} {{ $t('to') }}
            {{ formatHr(request.to_time) }} - {{ formatDate(request.date) }}
          </td>
          <td>
            <span class="pending" v-if="request.status == 1">{{
              $t("wait_inter")
            }}</span>
            <span class="completed" v-else>{{ $t("interview_ok") }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import moment from "moment";
import $ from "jquery";
import axios from "axios";
export default {
  data() {
    return {
      requestTable: [],
    };
  },
  methods: {
    FetchMoneyTable() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token-snadk")}`;
      axios.get("/your_transfer_history").then(({ data }) => {
        this.requestTable = data.data;
        console.log(this.requestTable);
      });
    },
    formatDate(value) {
      return moment(value).format("DD/MM/YY");
    },
    formatHr(value) {
      return moment(value, "HH:mm").format("h:mm");
    },
  },
  created() {
    this.FetchMoneyTable();
  },
  updated() {
    $("#RequestTableData").DataTable({
      language: {
        search: this.$t("search"),
        lengthMenu: this.$t("show") + " _MENU_ " + this.$t("questions"),
        paginate: {
          first: this.$t("first"),
          last: this.$t("last"),
          next: this.$t("next"),
          previous: this.$t("prev"),
        },
        info:
          this.$t("show") +
          " _START_ " +
          this.$t("from") +
          " _END_ " +
          this.$t("to") +
          " _TOTAL_ " +
          this.$t("questions"),
      },
    });
  },
};
</script>

<style></style>
