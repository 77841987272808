<template>
  <div class="sidebar_dashboard">
    <ul>
      <li :class="{ active: $route.name === 'homeDashboard' }">
        <router-link
          :to="{ name: 'homeDashboard' }"
          exact-active-class="active"
          active-class="active"
        >
          <img src="@/assets/image/home.png" alt="sidebar" />
          {{ $t("consultant") }}</router-link
        >
      </li>
      <li :class="{ active: $route.name === 'moneyDashboard' }">
        <router-link
          :to="{ name: 'moneyDashboard' }"
          exact-active-class="active"
          active-class="active"
        >
          <img src="@/assets/image/moneys.png" alt="sidebar" />
          {{ $t("date_payment") }}</router-link
        >
      </li>
      <li>
        <button class="btn" @click="logout">
          <img src="@/assets/image/logout.png" alt="sidebar" />
          {{ $t("Logout") }}
        </button>
      </li>
      <!-- <li>
        <router-link :to="{ name: 'consultantDashboard' }">
          {{ $t("general_setting") }}</router-link
        >
      </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      // remove the "user" key from local storage
      localStorage.removeItem("user");
      localStorage.removeItem("token-snadk");
      location.reload();
      // redirect to the login page
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style></style>
