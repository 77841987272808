<template>
  <div class="header_service">
    <div class="container">
      <div class="main_header">
        <h2 class="text-white">{{ header.title }}</h2>
        <p v-html="header.text" class="text-white"></p>
      </div>
      <div class="img">
        <img :src="header.image" alt="service" />
      </div>
    </div>
  </div>
</template>

<script>
import { serviceData } from "@/api/service/header.js";

export default {
  data() {
    return {
      header: {},
    };
  },
  mounted() {
    serviceData().then((response) => {
      this.header = response.data.data;
      // console.log(this.header);
      return response;
    });
  },
};
</script>

<style></style>
