<template>
  <section class="login_page container">
    <!-- <JoinsButtonsLogin /> -->
    <div class="mt-4">
      <div class="text">
        <h2>{{ $t("login") }}</h2>
        <p>{{ $t("plz_cont") }}</p>
      </div>
      <form @submit.prevent="login">
        <div class="input-group">
          <label class="form-label">{{ $t("email") }}</label>
          <input
            v-model="form.email"
            type="email"
            class="form-control"
            :placeholder="$t('full_email')"
            required
          />
          <span class="icon_form">
            <i class="bi bi-envelope"></i>
          </span>
        </div>

        <div class="input-group">
          <label class="form-label">{{ $t("password") }}</label>
          <input
            v-model="form.password"
            type="password"
            class="form-control"
            :placeholder="$t('password')"
            required
          />
          <span class="icon_form">
            <i class="bi bi-lock"></i>
          </span>
        </div>

        <router-link class="forget" :to="{ name: 'forgetPassword' }">
          {{ $t("forget_pass") }}
        </router-link>

        <button type="submit" class="btn first">{{ $t("login") }}</button>
      </form>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "login",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    async login() {
      try {
        axios.post("/login", this.form).then((response) => {
          const { api_token, is_lawyer, is_client } = response.data.data;

          localStorage.setItem("token-snadk", api_token);
          localStorage.setItem("user", JSON.stringify(response.data.data));

          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${api_token}`;

          this.$store.state.token = api_token;

          // console.log(this.$store.state.token, "token");

          if (response.data.status) {
            if (is_lawyer) {
              this.$router.push("/dashboard");
              this.$store.state.userAuth = true;
              
              // console.log("lawyer");
            } else if (is_client) {
              this.$router.push("/dashboard-user");
              this.$store.state.userAuth = true;
              // console.log("client");
            }
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: response.data.message,
              timer: 1500,
            });
          }
        });
      } catch (error) {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: error.response?.data?.message || "Login failed",
          timer: 1500,
        });
      }
    },
  },
};
</script>
<style scoped>
/* Add styles here */
</style>
