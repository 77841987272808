<template>
  <div class="condition">
    <div class="container">
      <h2>{{ $t("condition") }}</h2>
      <div class="row">
        <div class="col-12">
          <p v-html="condition.text"></p>
        </div>
        <!-- <div class="col-lg-6 col-12">
          <img style="width: 100%; height: 450px;" src="../assets/image/about.jpg" alt="" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      condition: {},
    };
  },
  methods: {
    //fetch country
    fetch_condition_data() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/fetch_condition_data").then(({ data }) => {
        this.condition = data.data;
        // console.log(this.heroData);
      });
    },
  },
  created() {
    this.fetch_condition_data();
  },
};
</script>

<style></style>
