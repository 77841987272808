<template>
  <div class="lawyer_header_second">
    <div class="main_header">
      <h2>{{ header.title }}</h2>
      <p v-html="header.text"></p>
    </div>
    <div class="img d-flex justify-content-center align-items-center">
      <img :src="header.image" alt="image" />
    </div>
  </div>
</template>

<script>
import { LawyerData } from "@/api/lawyer/header.js";

export default {
  data() {
    return {
      header: {},
    };
  },
  mounted() {
    LawyerData().then((response) => {
      this.header = response.data.data;
      return response;
    });
  },
};
</script>

<style scoped lang="scss">
.img {
  img {
    width: 40% !important;
    @media screen and (max-width: 991.98px) {
      width: 100% !important;
    }
  }
}
</style>
