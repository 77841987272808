<template>
  <div class="content" data-bs-spy="scroll" data-bs-target="#navbar-example">
    <div class="row">
      <div class="col-lg-2 col-md-4 col-12">
        <div class="y">
          <Navbar />
        </div>
      </div>
      <div class="col-lg-10 col-md-8 col-12">
        <div class="x">
          <About />
          <Experts />
          <Image />
          <Service />
          <image-liberary />
          <Reviews />
          <Contact />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Navbar from "@/components/lawyerDetials/content/navbar.vue";
import About from "@/components/lawyerDetials/content/about.vue";
import Experts from "@/components/lawyerDetials/content/experts.vue";
import Image from "@/components/lawyerDetials/content/lisinceImg.vue";
import Service from "@/components/lawyerDetials/content/service.vue";
import imageLiberary from "@/components/lawyerDetials/content/imageLiberary.vue";
import Contact from "@/components/lawyerDetials/content/contact.vue";
import Reviews from "@/components/lawyerDetials/content/customerReviews.vue";

export default {
  components: {
    Navbar,
    About,
    Experts,
    Service,
    Image,
    imageLiberary,
    Reviews,
    Contact,
  },
  data() {
    return {
      data: {},
    };
  },
  methods: {
    fetch_detials_data() {
      let id = { lawyer_id: this.$route.params.id };
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("/lawyer_details", id).then(({ data }) => {
        this.data = data.data;
        // console.log(this.cards);
      });
    },
  },
  created() {
    this.fetch_detials_data();
  },
};
</script>

<style></style>
