<template>
  <div class="cards_money">
    <div class="row">
      <div class="col-lg-4 col-12">
        <div class="card_money">
          <div class="img">
            <img src="@/assets/image/walet-3.png" alt="wallet" />
          </div>
          <div class="text">
            <h6>{{ $t("per_balance") }}</h6>
            <span> {{ card.rest_money }} {{ $t("sar") }} </span>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-12">
        <div class="card_money">
          <div class="img">
            <img src="@/assets/image/card2.png" alt="wallet" />
          </div>
          <div class="text">
            <h6>{{ $t("current_balance") }}</h6>
            <span> {{ card.guide_price }} {{ $t("sar") }} </span>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-12">
        <div class="card_money">
          <div class="img">
            <img src="@/assets/image/card3.png" alt="wallet" />
          </div>
          <div class="text">
            <h6>{{ $t("total_balance") }}</h6>
            <span> {{ card.total_money }} {{ $t("sar") }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      card: {},
    };
  },
  methods: {
    fetch_card_data() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token-snadk")}`;
      axios.get("/your_transfer_statistics").then(({ data }) => {
        this.card = data.data;
        // console.log(this.card);
      });
    },
  },
  created() {
    this.fetch_card_data();
  },
};
</script>

<style></style>
