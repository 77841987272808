<template>
  <section class="home_page">
    <Header />
    <filter-section />
    <about />
    <lawyer-section />
    <application />
    <service-section />
    <pranter />
  </section>
</template>

<script>
import Header from "@/components/home/header.vue";
import filterSection from "@/components/home/filter.vue";
import about from "@/components/home/aboutSection.vue";
import lawyerSection from "@/components/home/lawyerSection.vue";
import application from "@/components/home/application.vue";
import pranter from "@/components/home/pranter.vue";
import ServiceSection from "@/components/home/serviceSection.vue";

export default {
  components: {
    Header,
    about,
    lawyerSection,
    application,
    pranter,
    ServiceSection,
    filterSection,
  },
  created() {},
};
</script>

<style></style>
