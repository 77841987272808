<template>
  <div class="info infos">
    <a
      class="watts"
      :href="cardSS.whatsapp"
      target="__blank"
      v-show="cardSS.whatsapp"
      ><i class="bi bi-whatsapp"></i
    ></a>
    <a
      class="second btn text-center"
      :href="'mailto:' + cardSS.email"
      v-show="cardSS.email"
    >
      <i class="bi bi-envelope"></i> {{ $t("email_contacts") }}
    </a>
    <a
      class="second btn text-center"
      :href="cardSS.website"
      target="__blank"
      v-show="cardSS.website"
      ><i class="bi bi-globe-europe-africa"></i>{{ $t("show_website") }}
    </a>
  </div>
</template>

<script>
export default {
  props: ["cardSS"],
};
</script>

<style></style>
