<template>
  <div class="can_contact">
    <p>{{ $t("can_contact") }}</p>
    <ul>
      <li>
        <a target="__blank" :href="data.facebook">
          <img src="@/assets/image/face.png" alt="social" />
        </a>
      </li>

      <li>
        <a target="__blank" :href="data.twitter">
          <img src="@/assets/image/twiter.png" alt="social" />
        </a>
      </li>

      <li>
        <a target="__blank" :href="data.youtube">
          <img src="@/assets/image/youtube.png" alt="social" />
        </a>
      </li>

      <li>
        <a target="__blank" :href="data.tiktok">
          <img src="@/assets/image/tiktik.png" alt="social" />
        </a>
      </li>

      <li>
        <a target="__blank" :href="data.linkedin">
          <img src="@/assets/image/linkedin.png" alt="social" />
        </a>
      </li>

      <li>
        <a target="__blank" :href="data.instagram">
          <img src="@/assets/image/instgram.png" alt="social" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["data"],
};
</script>

<style></style>
