<template>
<div class="header_adv">
    <div class="main_header">
        <h2>{{ header.title }}</h2>
        <p v-html="header.text "></p> 
    </div>
    <img src="@/assets/image/shape2.png" alt="layer">
    
</div>
</template>

<script>
 import { advData } from "@/api/adv/header.js";

export default {
  data() {
    return {
      header: {},
    };
  },
  mounted() {
    advData().then((response) => {
      this.header = response.data.data;
        console.log(this.header);
      return response;
    });
  },
};
</script>

<style>

</style>