<template>
  <div class="laywer_service">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-12 col-12">
          <div class="text">
            <h2>{{ $t("lawyer_title") }}</h2>
            <p v-html="header.lawyer_service_text"></p>
            <router-link to="/contact" class="btn first">{{
              $t("contact")
            }}</router-link>
          </div>
        </div>
        <div class="col-lg-7 col-md-12 col-12 cards">
          <div
            class="card_service_layer"
            v-for="(card, index) in lawerService"
            :key="index"
          >
            <img :src="card.image" alt="service" />
            <h4>{{ card.title }}</h4>
            <p v-html="card.text"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { serviceData } from "@/api/service/lawyer.js";
import { textData } from "@/api/text/text.js";

export default {
  data() {
    return {
      lawerService: [],
      header: {},
    };
  },
  mounted() {
    serviceData().then((response) => {
      this.lawerService = response.data.data;
      // console.log(this.header);
      return response;
    });
    textData().then((response) => {
      this.header = response.data.data;
      // console.log(this.header);
      return response;
    });
  },
};
</script>

<style></style>
