<template>
  <section class="order_page">
    <breadcrumb :info="info" />
    <section class="filter_home filter_service">
      <div class="container">
        <div class="group">
          <div class="input-group">
            <!-- country -->
            <select
              class="form-select"
              aria-label="Default select example"
              @change="
                fetchState($event);
                fetch_lawyers_data();
              "
              v-model="country_id"
            >
              <option disabled selected>{{ $t("country_name") }}</option>
              <option
                v-for="(country, index) in countries"
                :key="index"
                :value="country.id"
              >
                {{ country.title }}
              </option>
            </select>
            <span class="icon"><i class="bi bi-geo-alt"></i></span>
            <span v-if="!country_id" class="text">{{
              $t("country_name")
            }}</span>
            <!-- country -->
          </div>
          <div class="input-group">
            <!-- state -->
            <select
              class="form-select"
              aria-label="Default select example"
              @change="
                fetchDepartment($event);
                fetch_lawyers_data();
              "
              v-model="state_id"
              :disabled="!country_id"
            >
              <option disabled selected>{{ $t("cities_name") }}</option>
              <option
                v-for="(state, index) in states"
                :key="index"
                :value="state.id"
              >
                {{ state.title }}
              </option>
            </select>
            <span class="icon"><i class="bi bi-geo-alt"></i></span>
            <span v-if="!state_id" class="text">{{ $t("cities_name") }}</span>
            <!-- state -->
          </div>
          <div class="input-group">
            <!-- city -->
            <select
              class="form-select"
              aria-label="Default select example"
              v-model="city_id"
              @change="fetch_lawyers_data()"
              :disabled="!state_id"
            >
              <option selected>{{ $t("department_name") }}</option>
              <option
                v-for="(city, index) in cities"
                :key="index"
                :value="city.id"
              >
                {{ city.title }}
              </option>
            </select>
            <span class="icon"><i class="bi bi-geo-alt"></i></span>
            <span v-if="!city_id" class="text">{{
              $t("department_name")
            }}</span>
            <!-- city -->
          </div>
          <!-- service -->
          <div class="input-group">
            <select
              class="form-select"
              aria-label="Default select example"
              @change="
                fetch_service($event);
                fetch_lawyers_data();
              "
              v-model="law_service_id"
              :disabled="!country_id"
            >
              <option disabled selected>{{ $t("service") }}</option>
              <option
                v-for="(service, index) in lawerService"
                :key="index"
                :value="service.id"
              >
                {{ service.title }}
              </option>
            </select>
            <span class="icon"><i class="bi bi-geo-alt"></i></span>
            <span v-if="!law_service_id" class="text">{{ $t("service") }}</span>
          </div>
          <!-- service -->
        </div>
      </div>
    </section>
    <section class="all_lawyer">
      <div class="container">
        <div class="row">
          <div
            class="col-lg-6 col-md-6 col-12"
            v-for="(cards, index) in lawyercards.data"
            :key="index"
          >
            <router-link
              @click="saveKey(cards.id)"
              :to="`/legality/order/${cards.id}`"
            >
              <card :legal="cards" />
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <div class="row">
      <pagination
        :data="lawyercards"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetch_lawyers_data"
      >
        <span slot="prev-nav">&lt;</span>
        <span slot="next-nav">&gt;</span>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import breadcrumb from "@/components/layout/breadcrumb.vue";
import card from "@/components/legalitySection/card.vue";

// import allLawyer from "@/components/legalitySection/allLawyer.vue";

export default {
  name: "order",
  components: {
    breadcrumb,
    pagination,
    card,
  },
  data() {
    return {
      info: {
        title: this.$t("addvice_order"),
      },
      lawyercards: {
        data: [],
      },
      lawerService: [],
      countries: [],
      states: [],
      cities: [],
      country_id: "",
      city_id: "",
      state_id: "",
      law_service_id: "",
    };
  },
  methods: {
    //pajination
    fetch_lawyers_data(page = 1) {
      const data = {};
      if (this.country_id) {
        data["country_id"] = this.country_id;
      }
      if (this.state_id) {
        data["state_id"] = this.state_id;
      }
      if (this.city_id) {
        data["city_id"] = this.city_id;
      }
      if (this.law_service_id) {
        data["law_service_id"] = this.law_service_id;
      }
      // if (this.exprince_id) {
      //   data["experience_years"] = this.exprince_id;
      // }
      axios.post(`filter_consultants?page=${page}`, data).then(({ data }) => {
        this.lawyercards = data.data;
        // console.log(this.lawyercards);
      });
    },

    //fetch country
    fetch_country_data() {
      axios.get("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
        // console.log(this.heroData);
      });
    },
    //fetch state
    fetchState(e) {
      let country = { country_id: e.target.value };
      axios.post("/fetch_states_by_country_id", country).then((data) => {
        // console.log(data.data);
        if (data.status == 200) {
          this.states = data.data.data;
        }
      });
    },

    //fetch city
    fetchDepartment(x) {
      let city = { state_id: x.target.value };
      axios.post("/fetch_cities_by_state_id", city).then((data) => {
        // console.log(data.data);
        if (data.status == 200) {
          this.cities = data.data.data;
          // console.log(this.department);
        }
      });
    },
    fetch_service() {
      axios.get("/fetch_law_services").then(({ data }) => {
        this.lawerService = data.data;

        //
        // console.log(this.category);
      });
    },
    saveKey(id) {
      const objectToSave = {
        serviceValue: this.law_service_id,
        cardId: id,
        // add any other required properties here
      };
      localStorage.setItem("legalkey1", JSON.stringify(objectToSave));
    },
  },
  created() {
    this.fetch_lawyers_data();
    this.fetch_country_data();
    this.fetch_service();
  },
};
</script>

<style></style>
