<template>
  <div class="lawyer_cards lawyer_cards_detials">
    <div class="img">
      <img :src="cards.image" alt="cards" />
    </div>
    <div class="information">
      <h4>{{ cards.name }}</h4>
      <p v-html="cards.description"></p>
      <div class="rating">
        <span v-for="(star, index) in cards.average_rate" :key="index">
          <i class="bi bi-star-fill"></i>
        </span>
        <span v-for="(star, index) in average_rate" :key="index">
          <i class="bi bi-star"></i>
        </span>
      </div>
      <div class="info">
        <i class="bi bi-telephone"></i>
        <a class="parg" :href="`tel:${cards.phone}`"> {{ cards.phone }} </a>
      </div>
      <div class="info">
        <i class="bi bi-geo-alt"></i>

        <p v-show="cards.address" class="parg">{{ cards.address }}</p>
        <span v-show="cards.map_link">
          <a :href="cards.map_link" target="__blank">
            <i class="bi bi-map-fill"></i
          ></a>
        </span>
      </div>
      <div class="info infos">
        <!-- <router-link class="btn first" :to="{ name: 'legalityLawyer' }">
          {{ $t("addvice_order") }}</router-link> -->
        <div
          class="dropdown sss"
          v-if="
            data.law_services != [] &&
            data.arbitration_services != [] &&
            data.law_categories != [] &&
            !user?.is_lawyer
          "
        >
          <button
            class="nav-link btn signup dropdown-toggle"
            data-bs-toggle="dropdown"
            role="button"
            aria-expanded="false"
          >
            {{ $t("ask_help") }}
          </button>
          <ul class="dropdown-menu">
            <li v-if="data.law_services.length">
              <router-link
                @click="addLegal(data.id)"
                class="link"
                :to="`/legality/order/${data.id}`"
              >
                {{ $t("addvice_order") }}</router-link
              >
            </li>
            <li v-if="data.arbitration_services.length">
              <router-link
                @click="addRefer(data.id)"
                class="link"
                :to="`/refer/order/${data.id}`"
              >
                {{ $t("refer_order") }}</router-link
              >
            </li>
            <li v-if="data.law_categories.length">
              <router-link
                @click="addcase(data.id)"
                class="link"
                :to="`/case/order/${data.id}`"
              >
                {{ $t("case_order") }}</router-link
              >
            </li>
          </ul>
        </div>
        <a
          v-show="cards.whatsapp"
          class="watts"
          :href="cards.whatsapp"
          target="_blank"
        >
          <i class="bi bi-whatsapp"></i>
        </a>
        <a
          v-show="cards.email"
          class="second btn text-center"
          :href="'mailto:' + cards.email"
        >
          <i class="bi bi-envelope"></i> {{ $t("email_contacts") }}
        </a>

        <a
          v-show="cards.website"
          class="second btn text-center"
          :href="cards.website"
          target="__blank"
          ><i class="bi bi-globe-europe-africa"></i>{{ $t("show_website") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "lawyer card detials",
  data() {
    return {
      ratingStars: [],
      average_rate: 0,
      user: JSON.parse(localStorage.getItem("user")),
      data: {
        law_services: [],
        arbitration_services: [],
        law_categories: [],
      },
    };
  },
  props: ["cards"],
  methods: {
    fetch_lawyer_details_data() {
      let id = { lawyer_id: this.$route.params.id };
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("/lawyer_details", id).then(({ data }) => {
        this.data = data.data;
        this.average_rate = 5 - data.data.average_rate;
        // console.log(this.data.law_services);
      });
    },
    addLegal(id) {
      // Create an object with the retrieved servicekey value and other required properties
      const objectToSaveLegal = {
        cardId: id,
        serviceValue: "",
      };
      // Save the object to local storage as a JSON string
      localStorage.setItem("legalkey1", JSON.stringify(objectToSaveLegal));
    },
    addRefer(id) {
      // Create an object with the retrieved servicekey value and other required properties
      const objectToSaveRefer = {
        cardId: id,
        serviceValue: "",
      };
      // Save the object to local storage as a JSON string
      localStorage.setItem("referkey1", JSON.stringify(objectToSaveRefer));
    },
    addcase(id) {
      // Create an object with the retrieved servicekey value and other required properties
      const objectToSaveCaes = {
        cardId: id,
        serviceValue: "",
      };
      // Save the object to local storage as a JSON string
      localStorage.setItem("casekey1", JSON.stringify(objectToSaveCaes));
    },
  },
  created() {
    this.fetch_lawyer_details_data();
  },
};
</script>

<style></style>
