<template>
  <div class="reservation">
    <h4 class="testt" v-if="!data.arbitration_services.length">
      {{ $t("no_service_law") }}
    </h4>

    <div class="text" v-if="data.arbitration_services.length">
      <h2>{{ $t("contonuis_reservation") }}</h2>
    </div>
    <div class="type" v-if="data.arbitration_services.length">
      <form class="advice" @submit.prevent="submitForm">
        <h4>{{ $t("choise_service") }}</h4>
        <!-- service -->

        <div class="input-group">
          <select
            class="form-select"
            aria-label="Default select example"
            @change="
              fetch_service_data();
              update_service_value();
            "
            v-model="law_arbitration_id"
          >
            <!-- <option disabled selected>{{ $t("service") }}</option> -->
            <option selected disabled value="serviceValue">
              {{ serviceValue }}
            </option>
            <option
              v-for="(cat, index) in data.arbitration_services"
              :key="index"
              :value="cat.id"
            >
              {{ cat.title }}
            </option>
          </select>
          <!-- <span class="icon"><i class="bi bi-geo-alt"></i></span> -->
          <!-- <span v-if="!law_arbitration_id" class="text second">{{ $t("service") }}</span> -->
        </div>
        <!-- service -->
        <h4>{{ $t("choose_reservation") }}</h4>
        <div class="input-group">
          <div class="form-check w-50">
            <div class="input-group">
              <label for="zero">
                <input
                  class="form-control"
                  type="radio"
                  value="1"
                  id="zero"
                  v-model="selectedValue"
                />
                <div class="contents">
                  <span><i class="bi bi-camera-video-fill"></i></span>
                  <h6>{{ $t("vedio_consult") }}</h6>
                </div>
              </label>
            </div>
          </div>
          <div class="form-check w-50">
            <div class="input-group">
              <label for="one">
                <input
                  class="form-control"
                  type="radio"
                  value="2"
                  id="one"
                  v-model="selectedValue"
                />
                <div class="contents">
                  <span><i class="bi bi-record-btn-fill"></i></span>
                  <h6>{{ $t("call_consult") }}</h6>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="take_day">
          <h6>
            {{ $t("take_day") }} <span>({{ $t("choise_date") }})</span>
          </h6>
          <input
            v-model="dateTime"
            @change="showTime()"
            class="form-control"
            type="date"
          />
        </div>
        <div v-show="!lists.length">
          <p>{{ $t("there_no_per") }}</p>
        </div>
        <div v-show="showCard" class="choose_situable">
          <h6>{{ $t("choose_period") }}</h6>
          <ul>
            <li v-for="(list, index) in lists" :key="index">
              <h6>{{ $t("perioud") }} {{ index + 1 }}</h6>
              <p>{{ $t("from") }} : {{ list.from_time }}</p>
              <p>{{ $t("to") }} : {{ list.to_time }}</p>
              <button
                @click="saveVar(list.id)"
                :disabled="user?.is_lawer"
                class="btn first"
              >
                {{ $t("book_now") }}
              </button>
            </li>
          </ul>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      selectedValue: "",
      lawyer_day_time: "",
      dateTime: "",
      user: JSON.parse(localStorage.getItem("user")),
      auth: localStorage.getItem("token-snadk"),
      lists: [],
      serviceValue: "",
      cards: {
        data: [],
      },
      data: {
        arbitration_services: [],
      },
      law_arbitration_id: "",
      serviceValue: "",
    };
  },
  mounted() {
    window.addEventListener("popstate", this.handlePopstate);
  },

  beforeDestroy() {
    window.removeEventListener("popstate", this.handlePopstate);
  },
  methods: {
    async submitForm() {
      // Validate phone number
      if (this.auth) {
        let data = {
          // lawyer_id: localStorage.getItem("id"),
          user_name: this.user.name,
          user_phone: this.user.phone,
          contact_type: this.selectedValue,
          date: this.dateTime,
          lawyer_day_time_id: this.lawyer_day_time,
          lawyer_id: this.$route.params.id,
          arbitration_service_id: this.law_arbitration_id,
        };
        const newLocal = this.$i18n.locale;
        axios.defaults.headers.common["Accept-Language"] = newLocal;
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${localStorage.getItem("token-snadk")}`;

        axios.post("/storeJudgerRequest  ", data).then(({ data }) => {
          if (data.status == true) {
            Swal.fire({
              position: "top-center",
              icon: "success",
              title: data.message,
              timer: 1500,
            });
            localStorage.removeItem("referkey");
            localStorage.removeItem("referkey1");
          } else if (data.status == false) {
            Swal.fire({
              position: "top-center",
              icon: "error",
              title: data.message,
              timer: 1500,
            });
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: this.$t("Please_login_first"),
          timer: 1500,
        });
      }
    },
    //make select diseabled when key in local storage have value
    // isServiceValueEmpty() {
    //   const referKey = localStorage.getItem("referkey1");
    //   if (referKey) {
    //     const { serviceValue } = JSON.parse(referKey);
    //     if (serviceValue) {
    //       this.serviceValue = serviceValue;
    //     }
    //     return !serviceValue;
    //   }
    //   return true;
    // },
    fetch_data_data() {
      let id = { lawyer_id: this.$route.params.id };
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("/lawyer_details", id).then(({ data }) => {
        this.cards = data.data;
        // console.log(this.cards);
      });
    },
    async showTime() {
      let lawyer_id = this.$route.params.id;
      let data = {
        lawyer_id: lawyer_id,
        date: this.dateTime,
      };
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("/fetch_lawyer_times_by_date", data).then(({ data }) => {
        if (data.status == true) {
          this.lists = data.data;
          this.showCard = true;
        } else if (data.status == false) {
          // Show SweetAlert with an error message
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "error",
          });
        }
      });
    },
    saveVar(id) {
      // Create an object with the retrieved servicekey value and other required properties
      const objectToSave = {
        contact_type: this.selectedValue,
        date: this.dateTime,
        lawyer_day_time: id,
      };

      this.lawyer_day_time = id;
      // Save the object to local storage as a JSON string
      localStorage.setItem("referkey", JSON.stringify(objectToSave));
    },

    fetch_service_data() {
      let id = { lawyer_id: this.$route.params.id };
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("/lawyer_details", id).then(({ data }) => {
        this.data = data.data;
        // console.log(this.cards);
      });
      //check if local storage value have service
      if (localStorage.getItem("referkey1")) {
        const referkey1 = JSON.parse(localStorage.getItem("referkey1"));
        console.log(referkey1.serviceValue);
        if (referkey1.serviceValue == "") {
          // Update the value of referkey1 in local storage with the new law_services_id value
          referkey1.serviceValue = this.law_arbitration_id;
          localStorage.setItem("referkey1", JSON.stringify(referkey1));
        }
      }
    },
    update_service_value() {
      // Update the value of referkey1 in local storage with the new law_services_id value
      const referkey1 = JSON.parse(localStorage.getItem("referkey1")) || {};
      referkey1.serviceValue = this.law_arbitration_id;
      localStorage.setItem("referkey1", JSON.stringify(referkey1));
    },
    handlePopstate() {
      localStorage.removeItem("referkey1");
    },
  },
  created() {
    this.fetch_data_data();
    this.fetch_service_data();
  },
};
</script>

<style></style>
