<template>
  <div
    v-if="data.law_services == null"
    class="service_office"
    id="scrollspyHeading2"
  >
    <h2>{{ $t("office_service") }}</h2>
    <!-- <p>{{ $t('service_text') }}</p> -->

    <ul>
      <li v-for="(lists, index) in data.law_services" :key="index">
        {{ lists.title }}
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      data: {},
    };
  },
  methods: {
    fetch_blog_data() {
      let id = { lawyer_id: this.$route.params.id };
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("/lawyer_details", id).then(({ data }) => {
        this.data = data.data;
        // console.log(this.cards);
      });
    },
  },
  created() {
    this.fetch_blog_data();
  },
};
</script>
<style></style>
