<template>
  <section class="pranter_section">
    <div class="container">
      <swiper
        :loop="true"
        :autoplay="{
          delay: 2500,
          disableOnInteraction: false,
        }"
        :breakpoints="{
          '320': {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          '425': {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          '768': {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          '1024': {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }"
        :modules="modules"
        class="mySwiper"
      >
        <swiper-slide
          v-for="(pranters, index) in Partner"
          :key="index"
          class="d-flex justify-content-center align-items-center"
        >
          <img :src="pranters.image" />
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
import { pranterData } from "@/api/home/pranter.js";
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";
import { Autoplay } from "swiper";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      Partner: [],
    };
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
  mounted() {
    pranterData().then((response) => {
      this.Partner = response.data.data;
      //  console.log(this.header);
      return response;
    });
  },
};
</script>

<style></style>
