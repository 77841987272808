<template>
  <div class="lawyer_cards">
    <router-link :to="`/guide/${cardSS.id}`">
      <div class="img">
        <img
          :src="cardSS.image || '@/assets/image/logo.png'"
          alt="cards"
          @error="handleImageError"
        />
      </div>
    </router-link>
    <div class="information">
      <h4>{{ cardSS.name }}</h4>

      <p v-html="cardSS.description.substring(0, 120) + ' ...'"></p>

      <div class="info" v-show="cardSS.phone">
        <i class="bi bi-telephone"></i>
        <a class="parg" :href="`tel:${cardSS.phone}`"> {{ cardSS.phone }} </a>
      </div>

      <div class="info">
        <i v-show="cardSS.address" class="bi bi-geo-alt"></i>
        <p v-show="cardSS.address" class="parg">{{ cardSS.address }}</p>
        <span v-show="cardSS.map_link">
          <a :href="cardSS.map_link" target="__blank">
            <i class="bi bi-map-fill"></i
          ></a>
        </span>
      </div>

      <LawyerContactInfo :cardSS="cardSS" />
    </div>
    <!-- <div class="drop_menu">
      <span @click="show = !show"
        ><i class="bi bi-three-dots-vertical"></i
      ></span>
      <dropMenu v-show="show" />
    </div> -->
  </div>
</template>

<script>
// import dropMenu from "@/components/lawyer/dropMenu.vue";
import LawyerContactInfo from "@/components/lawyer/lawyerContactInfo.vue";

export default {
  components: {
    // dropMenu,
    LawyerContactInfo,
  },
  data() {
    return {
      show: false,
      hide: true,
    };
  },
  props: ["cardSS"],
  methods: {
    handleImageError(event) {
      event.target.src = require("@/assets/image/logo.png");
    },
  },
};
</script>

<style></style>
