<template>
  <section class="join_page">
    <div class="container">
      <JoinsButtonsUser />

      <form @submit.prevent="submitForm">
        <!-- start first stage -->
        <div class="row">
          <div class="text py-5">
            <h2>{{ $t("join_us") }}</h2>
            <p>{{ $t("join_desc") }}</p>
          </div>
          <div class="col-lg-7 col-12">
            <div>
              <!-- start input group -->
              <div class="input-group">
                <label class="form-label">
                  {{ $t("full_name") }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('enter_full_name')"
                  v-model="join.name"
                  @input="validateName"
                  required
                />
                <span class="icon_form">
                  <i class="bi bi-person"></i>
                </span>
              </div>
              <span class="error" v-if="errors.name">{{ errors.name }}</span>
              <!-- finish input group -->

              <!-- start input group -->
              <div class="input-group">
                <label class="form-label">
                  {{ $t("phones") }}
                </label>
                <input
                  type="tel"
                  class="form-control"
                  :placeholder="$t('phone_placeholder')"
                  v-model="join.phone"
                  @input="validatePhone"
                  required
                />
                <span class="icon_form">
                  <i class="bi bi-telephone"></i>
                </span>
              </div>
              <span class="error" v-if="errors.phone">{{ errors.phone }}</span>
              <!-- finish input group -->

              <!-- start input group -->
              <div class="input-group">
                <label class="form-label">
                  {{ $t("email") }}
                </label>
                <input
                  type="email"
                  class="form-control"
                  :placeholder="$t('full_email')"
                  v-model="join.email"
                  @input="validateEmail"
                  required
                />
                <span class="icon_form">
                  <i class="bi bi-envelope"></i>
                </span>
              </div>
              <span class="error" v-if="errors.email">{{ errors.email }}</span>

              <!-- start input group -->
              <div class="input-group">
                <label class="form-label">
                  {{ $t("password") }}
                </label>
                <input
                  type="password"
                  class="form-control"
                  :placeholder="$t('password')"
                  v-model="join.password"
                  @input="validatePassword"
                  required
                />
                <span class="icon_form">
                  <i class="bi bi-lock"></i>
                </span>
              </div>
              <span class="error" v-if="errors.password">{{
                errors.password
              }}</span>

              <!-- start input group -->
              <div class="input-group">
                <div class="button">
                  <button class="next first btn">
                    {{ $t("cont") }}
                  </button>
                </div>
              </div>
              <!-- finish input group -->
            </div>
          </div>
          <div class="col-lg-5 col-12">
            <div class="img">
              <img src="@/assets/image/contract_2.png" alt="contract" />
            </div>
          </div>
        </div>
        <!-- finish first stage -->

        <!-- start second stage -->

        <!-- start second stage -->
      </form>
    </div>
    <!-- <second-step /> -->
  </section>
</template>

<script>
import JoinsButtonsUser from "@/components/join/joinsButtons/lawyer.vue";

import Swal from "sweetalert2";
import axios from "axios";
import faq from "@/components/join/faq.vue";
// import { joinData } from "@/api/join/packages.js";
// import Multiselect from "vue-multiselect";

// import Form from "@/components/join/form.vue";
export default {
  components: {
    faq,
    // Multiselect,
    JoinsButtonsUser,
  },
  data() {
    return {
      step: 1,
      join: {
        name: "",
        password: "",
        phone: "",
        email: "",
        adress: "",
        adress2: "",
        uploadedImageName: "",
        about_ar: "",
        about_en: "",
        law_service_ids: [],
        country_id: "",
        city_id: "",
        state_id: "",
        package_id: "",
        social: "",
        whatsapp: "",
        twitter: "",
        facebook: "",
        linkedin: "",
        instagram: "",
        tiktok: "",
        youtube: "",
        addSocial: [],
      },
      fileData: {
        name: "",
      },
      packages: {},
      Option: [],
      selectedOption: "index",
      services: [],
      countries: [],
      cities: [],
      states: [],

      nameFile: "",
      uploadedImage: null,
      fileUploaded: "",
      errors: {
        name: "",
        email: "",
        phone: "",
        aboutEnError: "",
        aboutArError: "",
        addressEnError: "",
        addressArError: "",
      },
    };
  },
  computed: {
    isValid() {
      return (
        this.addressEnError === "" ||
        this.addressArError === "" ||
        this.aboutEnError === "" ||
        this.aboutArError === ""
      );
    },
  },
  methods: {
    //upload file
    // handleFileUpload(event) {
    //   this.fileData = event.target.files[0];
    //   // console.log(this.fileData);
    //   const reader = new FileReader();
    //   reader.readAsDataURL(this.fileData);
    //   reader.onload = () => (this.fileUploaded = reader.result);
    // },

    //slected package
    // selectOption(index) {
    //   this.selectedOption = index;
    // },
    //fetch country data
    // fetch_country_data() {
    //   const newLocal = this.$i18n.locale;
    //   axios.defaults.headers.common["Accept-Language"] = newLocal;
    //   axios.get("/fetch_countries").then(({ data }) => {
    //     this.countries = data.data;
    //     // console.log(this.heroData);
    //   });
    // },
    //fetch city data
    // fetchState(e) {
    //   const newLocal = this.$i18n.locale;
    //   axios.defaults.headers.common["Accept-Language"] = newLocal;
    //   let country = { country_id: e.target.value };
    //   axios.post("/fetch_states_by_country_id", country).then((data) => {
    //     // console.log(data.data);
    //     if (data.status == 200) {
    //       this.states = data.data.data;
    //     }
    //   });
    // },
    //fetch statment data
    // fetchDepartment(x) {
    //   const newLocal = this.$i18n.locale;
    //   axios.defaults.headers.common["Accept-Language"] = newLocal;
    //   let state = { state_id: x.target.value };
    //   axios.post("/fetch_cities_by_state_id", state).then((data) => {
    //     // console.log(data.data);
    //     if (data.status == 200) {
    //       this.cities = data.data.data;
    //       // console.log(this.department);
    //     }
    //   });
    // },

    // fetch_law_services() {
    //   const newLocal = this.$i18n.locale;
    //   axios.defaults.headers.common["Accept-Language"] = newLocal;
    //   axios.get("/fetch_law_services").then(({ data }) => {
    //     this.services = data.data;
    //     // console.log(this.services);
    //   });
    // },

    // uploadFiles() {
    //   const uploadedFiles = this.$refs.fileInput.files;
    //   for (let i = 0; i < uploadedFiles.length; i++) {
    //     this.join.files.push(uploadedFiles[i]);
    //   }
    //   const formData = new FormData();
    //   for (let i = 0; i < this.join.files.length; i++) {
    //     formData.append("files", this.join.files[i]);
    //   }
    // },
    // removeFile(index) {
    //   this.join.files.splice(index, 1);
    // },
    //add links to social
    // addLinks() {
    //   let x = this.join.social;
    //   console.log(x);
    //   this.join.addSocial.push(x);
    //   x = "";
    // },
    // //remove link from social
    // removeLink(index) {
    //   this.join.addSocial.splice(index, 1);
    // },

    validateName() {
      if (!this.join.name) {
        this.errors.name = this.$t("field is required");
      } else {
        this.errors.name = "";
      }
    },
    validateEmail() {
      if (!this.join.email) {
        this.errors.email = this.$t("field is required");
      } else if (
        !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.join.email)
      ) {
        this.errors.email = this.$t("Email is invalid");
      } else {
        this.errors.email = "";
      }
    },
    validatePhone() {
      if (!this.join.phone) {
        this.errors.phone = this.$t("field is required");
      } else if (!/^\d{9,13}$/.test(this.join.phone)) {
        this.errors.phone = this.$t("Phone is invalid");
      } else {
        this.errors.phone = "";
      }
    },

    submitForm() {
      this.validateName();
      this.validateEmail();
      this.validatePhone();

      const formData = new FormData();
      formData.append("name", this.join.name);
      formData.append("email", this.join.email);
      formData.append("phone", this.join.phone);
      formData.append("password", this.join.password);
      formData.append("password_confirmation", this.join.password);

      axios
        .post("/clientRegister", formData)
        .then((response) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
          });
          this.$router.push("/login");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  // mounted() {
  //   joinData().then((response) => {
  //     this.packages = response.data.data;
  //     // console.log(this.header);
  //     return response;
  //   });
  // },
  // created() {
  //   this.fetch_country_data();
  //   this.fetch_law_services();
  // },
};
</script>

<!-- <style src="vue-multiselect/dist/vue-multiselect.css"></style> -->
