import { createApp } from "vue";
import App from "./App.vue";
import { createI18n } from "vue-i18n";
import "bootstrap/dist/css/bootstrap.min.css";
// import "VueStarRating";
import "bootstrap";
import "hover.css";
import FlagIcon from "vue-flag-icon";
import VueEasyLightbox from "vue-easy-lightbox";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import axios from "axios";
import VueSocialSharing from "vue-social-sharing";
import rate from "vue-rate";
import "vue-rate/dist/vue-rate.css";
import VueSocialChat from "vue-social-chat";
import "vue-social-chat/dist/style.css";
import router from "./router/router.js";
import store from "../src/store/index.js";

// import store from './store/index.js';

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
let lang = "ar";
if (localStorage.getItem("lang")) {
  lang = localStorage.getItem("lang");
}

const i18n = createI18n({
  locale: lang, // set locale
  allowComposition: true, // you need to specify that!
  fallbackLocale: lang, // set fallback locale
  messages: loadLocaleMessages(), // set locale messages
});

const app = createApp(App);
// axios baseurl
const newLocal = lang;
axios.defaults.headers.common["Accept-Language"] = newLocal;
axios.defaults.baseURL = "https://portal.snadk.net/website";
axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "token-snadk"
)}`;

app.config.globalProperties.axios = axios;
app.config.globalProperties.$websiteUrl = "https://portal.snadk.net/";
app.use(i18n);
app.use(router);
app.use(FlagIcon);
app.use(VueEasyLightbox);
app.use(VueSocialSharing);
app.use(VueSweetalert2);
app.use(rate);
app.use(VueSocialChat);
app.use(store);
app.mount("#app");
