<template>
  <div class="filter_lawyer">
    <div class="input-group">
      <!-- country -->
      <select
        v-model="country_id"
        class="form-select"
        aria-label="Default select example"
        @change="fetchState($event)"
      >
        <option disabled selected>{{ $t("country_name") }}</option>
        <option
          v-for="(country, index) in countries"
          :key="index"
          :value="country.id"
        >
          {{ country.title }}
        </option>
      </select>
      <span v-if="!country_id" class="text">{{ $t("country_name") }}</span>
      <!-- country -->
    </div>
    <div class="input-group">
      <!-- state -->
      <select
        v-model="state_id"
        class="form-select"
        aria-label="Default select example"
        @change="fetchDepartment($event)"
        :disabled="!country_id"
      >
        <option selected>{{ $t("cities_name") }}</option>
        <option v-for="(state, index) in states" :key="index" :value="state.id">
          {{ state.title }}
        </option>
      </select>
      <span v-if="!state_id" class="text">{{ $t("cities_name") }}</span>
      <!-- staet -->
    </div>
    <div class="input-group">
      <!-- department -->
      <select
        v-model="city_id"
        :disabled="!state_id"
        class="form-select"
        aria-label="Default select example"
      >
        <option disabled selected>{{ $t("department_name") }}</option>
        <option v-for="(city, index) in cities" :key="index" :value="city.id">
          {{ city.title }}
        </option>
      </select>
      <span v-if="!city_id" class="text">{{ $t("department_name") }}</span>
      <!-- department -->
    </div>
    <div class="input-group">
      <h4>{{ $t("cat") }}</h4>
      <div class="form-check" v-for="(cats, index) in category" :key="index">
        <input
          class="form-check-input"
          type="checkbox"
          :value="cats.id"
          :id="`flexCheckChecked${index}`"
          v-model="category_id"
        />
        <label class="form-check-label" :for="`flexCheckChecked${index}`">
          {{ cats.title }}
        </label>
      </div>
    </div>

    <div class="input-group">
      <h4>{{ $t("exp_yaers") }}</h4>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          id="zero"
          value="0"
          v-model="experience_years"
        />
        <label class="form-check-label" for="zero">{{ $t("zero") }}</label>
      </div>

      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          id="one"
          value="1"
          v-model="experience_years"
        />
        <label class="form-check-label" for="one">{{ $t("one") }} </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          id="two"
          value="2"
          v-model="experience_years"
        />
        <label class="form-check-label" for="two">{{ $t("two") }}</label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          id="three"
          value="3"
          v-model="experience_years"
        />
        <label class="form-check-label" for="three">{{ $t("three") }}</label>
      </div>
    </div>

    <div class="button">
      <router-link @click="saveData" :to="{ name: 'Giude' }" class="btn first">
        {{ $t("search_now") }}</router-link
      >
    </div>
    <div class="avdts mt-5">
      <img src="@/assets/image/asds.jpg" class="w-100" alt="" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      countries: [],
      cities: [],
      states: [],
      category: [],
      // exprince: '',
      country_id: "",
      city_id: "",
      state_id: "",
      category_id: [],
      experience_years: "",
    };
  },
  computed: {
    searchButtonClass() {
      if (this.country_id) {
        return "btn-enabled";
      } else {
        return "btn-disabled";
      }
    },
  },
  methods: {
    fetch_country_data() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
        // console.log(this.heroData);
      });
    },
    fetchState(e) {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      let country = { country_id: e.target.value };
      axios.post("/fetch_states_by_country_id", country).then((data) => {
        // console.log(data.data);
        if (data.status == 200) {
          this.states = data.data.data;
        }
      });
    },
    fetchDepartment(x) {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      let state = { state_id: x.target.value };
      axios.post("/fetch_cities_by_state_id", state).then((data) => {
        // console.log(data.data);
        if (data.status == 200) {
          this.cities = data.data.data;
          // console.log(this.department);
        }
      });
    },
    fetch_category() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/fetch_law_categories").then(({ data }) => {
        this.category = data.data;
        // console.log(this.category);
      });
    },
    saveData() {
      const data = {};
      if (this.country_id) {
        data["country_id"] = this.country_id;
      }
      if (this.city_id) {
        data["city_id"] = this.city_id;
      }
      if (this.state_id) {
        data["state_id"] = this.state_id;
      }
      if (this.category_id) {
        data["law_category_ids"] = this.category_id;
      }
      if (this.experience_years) {
        data["experience_years"] = this.experience_years;
      }
      localStorage.setItem("myData", JSON.stringify(data));
    },
  },
  created() {
    this.fetch_country_data();
    this.fetch_category();
  },
};
</script>

<style></style>
