<template>
  <div class="row" v-show="list">
    <lawyerCards
      v-for="(card, index) in lawyercards.data"
      :key="index"
      :cardSS="card"
    >
    </lawyerCards>
    <div class="row">
      <pagination
        :data="lawyercards"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetch_lawyers_data"
      >
        <span slot="prev-nav">&lt;</span>
        <span slot="next-nav">&gt;</span>
      </pagination>
    </div>
    <h1 v-show="!lawyercards.data.length === 0">{{ noDataMessage }}</h1>
  </div>
</template>

<script>
import lawyerCards from "@/components/lawyer/lawyerCards.vue";
import pagination from "laravel-vue-pagination";

export default {
  components: { lawyerCards, pagination },
  props: ["list", "lawyercards", "fetch_lawyers_data", "noDataMessage"],
};
</script>

<style></style>
