import { createStore } from "vuex";

import authModule from "./modules/auth/index.js";

const store = createStore({
  state() {
    return {
      userAuth: false,
    };
  },
  modules: {
    auth: authModule,
  },
});

export default store;
