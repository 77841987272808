<template>
  <div class="container">
    <div class="row about_lawyer_citizen">
      <div class="col-lg-6 col-md-12 col-sm-12">
        <div class="text">
          <h2>{{ citizen.title }}</h2>
          <p v-html="citizen.text"></p>
          <router-link class="first btn" to="/service">{{
            $t("know_more")
          }}</router-link>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12">
        <div class="img justify-content-end">
          <img :src="citizen.image" alt="service" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { citizenData } from "@/api/about/citizen.js";

export default {
  data() {
    return {
      citizen: {},
    };
  },
  mounted() {
    citizenData().then((response) => {
      this.citizen = response.data.data;
      //    console.log(this.header);
      return response;
    });
  },
};
</script>

<style></style>
