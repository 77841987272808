<template>
  <section class="about_section">
    <div class="container">
      <div class="row">
        
        <div class="col-lg-6 col-md- col-12">
          <div class="text">
            <h2>{{ about.title }}</h2>
            <p  v-html="about.text"></p>
            <div class="links">
              <router-link class="btn first" to="/about">
                {{ $t("know_more") }}</router-link
              >
              <span data-bs-toggle="modal" data-bs-target="#exampleVideo"> <i class="bi bi-play-circle"></i></span>
            </div>
            <modal :video="about.video" />
          </div>
        </div>

        <div class="col-lg-6 col-md- col-12">
          <div class="img">
            <!-- <img :src="about.image" alt="about"> -->
            <img src="@/assets/image/about.jpg" alt="about" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { aboutSectionData } from "@/api/home/aboutSection.js";
import modal from "@/components/layout/modal.vue";

export default {
  components:{
    modal
  },
  data() {
    return {
      about: {},
      show:false,
    };
  },
  methods:{
    showPop(){
    this.show = !this.show;
    }
  },
  mounted() {
    aboutSectionData().then((response) => {
      this.about = response.data.data;
      // console.log(this.header);
      return response;
    });
  },
};
</script>

<style>
@media only screen and (max-width: 991.98px) {
  .about_section{
/* margin: 3rem 0 !important; */
  }
}
</style>
