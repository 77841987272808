<template>
  <section class="service_section">
    <div class="container">
      <div class="main_header">
        <h2>{{ $t("citizen_title") }}</h2>
        <p v-html="header.citizen_service_text"></p>
      </div>
      <div class="row services_box">
        <div
          class="col-lg-4 col-md-6 col-12 service_box"
          v-for="(services, index) in citizen"
          :key="index"
        >
          <img :src="services.image" alt="service" />
          <h4>{{ services.title }}</h4>
          <p v-html="services.text"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { serviceData } from "@/api/service/citizen.js";
import { textData } from "@/api/text/text.js";

export default {
  data() {
    return {
      citizen: [],
      header: {},
    };
  },
  mounted() {
    serviceData().then((response) => {
      this.citizen = response.data.data;
      //    console.log(this.header);
      return response;
    });
    textData().then((response) => {
      this.header = response.data.data;
      // console.log(this.header);
      return response;
    });
  },
};
</script>

<style></style>
